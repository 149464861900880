html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

/* remember to define focus styles! */
:focus {
  outline: 0; }

/* remember to highlight inserts somehow! */
ins {
  text-decoration: none; }

del {
  text-decoration: line-through; }

/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0; }

body {
  font-size: 62.2%;
  background-color: #121d3f; }

#navigation {
  width: 960px;
  margin: 30px auto 0;
  position: relative;
  background: url(/assets/images/nav-bg.gif) no-repeat left bottom; }

#navigation:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden; }

#navigation h2.small-logo {
  width: 280px;
  height: 43px;
  text-indent: -9000px;
  background: url(/assets/images/small-logo.gif) no-repeat 0 0;
  position: absolute;
  margin-top: -5px; }

#navigation ul {
  width: 660px;
  height: 36px;
  float: right; }

#navigation li a {
  float: left;
  display: block;
  text-decoration: none;
  color: #fff;
  background-image: url(/assets/images/nav.gif);
  height: 36px;
  text-indent: -9000px; }

#navigation .home {
  width: 80px;
  background-position: 0 0; }

#navigation .home:hover,
#navigation .home.here {
  background-position: 0 -36px; }

#navigation .services {
  width: 134px;
  background-position: -80px 0; }

#navigation .services:hover,
#navigation .services.here {
  background-position: -80px -36px; }

#navigation .blog {
  width: 108px;
  background-position: -214px 0; }

#navigation .blog:hover,
#navigation .blog.here {
  background-position: -214px -36px; }

#navigation .about {
  width: 118px;
  background-position: -322px 0; }

#navigation .about:hover,
#navigation .about.here {
  background-position: -322px -36px; }

#navigation .media {
  width: 114px;
  background-position: -440px 0; }

#navigation .media:hover,
#navigation .media.here {
  background-position: -440px -36px; }

#navigation .contact {
  width: 106px;
  background-position: -554px 0; }

#navigation .contact:hover,
#navigation .contact.here {
  background-position: -554px -36px; }

#intro {
  clear: both;
  width: 960px;
  min-height: 171px;
  background: url(/assets/images/logo.gif) no-repeat;
  margin: 0 auto;
  padding-top: 34px; }

#intro h1 {
  margin-left: 300px; }

#intro p {
  color: #afb6cc;
  font: normal 1.8em/1.4 Helvetica, Arial, Geneva, sans-serif;
  margin-left: 305px;
  margin-right: 20px; }

#intro .intromore {
  display: block;
  color: #9ab2ff;
  text-decoration: none; }

#intro .intromore:hover {
  color: #648ed7; }

.homediv {
  margin: 50px auto; }

#travel {
  width: 960px;
  min-height: 114px;
  background: url(/assets/images/events.png) no-repeat right center;
  padding-top: 40px; }

#travel h2 {
  margin: 0 0 0 10px; }

#travel p {
  margin: 0 0 0 10px;
  color: #afb6cc;
  font: normal 1.4em/1.5 Helvetica, Arial, Geneva, sans-serif;
  width: 270px; }

#perfect {
  width: 960px;
  min-height: 136px;
  background-image: url(/assets/images/perfect.jpg);
  padding-top: 48px; }

#perfect h2 {
  margin: 0 0 0 680px; }

#perfect p {
  margin: 0 0 0 680px;
  font: normal 1.4em/1.5 Helvetica, Arial, Geneva, sans-serif;
  width: 260px;
  color: #afb6cc; }

#slogan {
  width: 960px; }

#slogan h2 {
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 3em;
  text-indent: -9000px;
  height: 54px;
  background: url(/assets/images.gif) center top; }

#wrapper {
  clear: both;
  width: 960px;
  margin: 20px auto;
  background: url(/assets/images/hr.gif) no-repeat center top;
  overflow: hidden;
  padding: 2px 0 0 0; }

#wrapper.nobreak {
  background-image: none; }

#mainstuff {
  width: 660px;
  float: right;
  margin: 20px 0; }

#mainstuff p {
  margin: 15px 0;
  font: normal 1.4em/1.5 Helvetica, Arial, Geneva, sans-serif;
  color: #afb6cc; }

#mainstuff h3 {
  font: bold 1.8em/1.5 Helvetica, Arial, Geneva, sans-serif;
  color: #5f6672;
  margin: 15px 0; }

#mainstuff h3.gold {
  width: 177px;
  height: 36px;
  text-indent: -9000px;
  background-image: url(/assets/images/gold.gif); }

#mainstuff h3.silver {
  width: 195px;
  height: 36px;
  text-indent: -9000px;
  background-image: url(/assets/images/silver.gif); }

#mainstuff h3.bronze {
  width: 211px;
  height: 36px;
  text-indent: -9000px;
  background-image: url(/assets/images/bronze.gif); }

#mainstuff #bloglist {
  margin: 20px 0; }

#mainstuff #bloglist li {
  width: 660px;
  background: url(/assets/images/hr.gif) repeat-x left bottom;
  overflow: hidden; }

#mainstuff #bloglist a {
  font: normal 1.6em/1 Helvetica, Arial, Geneva, sans-serif;
  color: #afb6cc;
  text-decoration: none;
  padding: 10px 0 11px;
  margin: 0;
  float: left; }

#mainstuff #bloglist span {
  float: right;
  padding: 10px 0 11px;
  margin: 0;
  color: #afb6cc;
  font: normal 1.6em/1 Helvetica, Arial, Geneva, sans-serif; }

#contactform {
  margin: 20px 0; }

#contactform label {
  margin: 8px 0;
  font: normal 1.4em/1.5 Helvetica, Arial, Geneva, sans-serif;
  color: #afb6cc;
  display: block; }

#contactform input {
  margin: 0 0 10px;
  padding: 5px;
  font: normal 1.4em/1 Helvetica, Arial, Geneva, sans-serif;
  width: 300px;
  color: #0e1733;
  background-color: #a7c4ff; }

#contactform textarea {
  margin: 0 0 10px;
  padding: 5px;
  font: normal 1.4em/1 Helvetica, Arial, Geneva, sans-serif;
  width: 600px;
  color: #0e1733;
  background-color: #a7c4ff; }

#contactform button {
  padding: 5px;
  font: normal 1.4em/1 Helvetica, Arial, Geneva, sans-serif; }

#sidebar {
  width: 280px;
  float: left;
  margin: 20px 0; }

#sidebar h2 {
  font: bold 1.8em/1.2 Helvetica, Arial, Geneva, sans-serif;
  color: #5f6672;
  margin: 0 0 10px; }

#sidebar h2.tweet {
  width: 183px;
  height: 62px;
  text-indent: -9000px;
  background-image: url(/assets/images/tweet.gif); }

#sidebar h2.like {
  width: 190px;
  height: 24px;
  text-indent: -9000px;
  background-image: url(/assets/images/likes.gif);
  margin-top: 0;
  margin-bottom: 5px; }

#sidebar #twitter_div ul {
  margin: 15px 0 10px; }

#sidebar #twitter_div ul li {
  margin: 0 0 2px;
  font: normal 1.4em/1.4 Helvetica, Arial, Geneva, sans-serif;
  color: #afb6cc;
  padding: 14px 5px 8px;
  background: #0e1836 url(/assets/images/tweetop.gif) no-repeat left top; }

#sidebar #link_list ul li {
  margin: 0 0 2px;
  font: normal 1.4em/1.4 Helvetica, Arial, Geneva, sans-serif;
  color: #afb6cc;
  padding: 14px 5px 8px;
  background: #0e1836 url(/assets/images/tweetop.gif) no-repeat left top; }

#sidebar p {
  margin: 10px 0;
  font: italic 1.2em/1.7 Helvetica, Arial, Geneva, sans-serif;
  color: #afb6cc; }

#sidebar a {
  color: #9ab2ff;
  text-decoration: none; }

#sidebar a:hover {
  color: #648ed7; }

h2.needs {
  background: url(/assets/images/needs.gif) no-repeat;
  width: 599px;
  height: 31px;
  text-indent: -9000px; }

h2.dropus {
  background: url(/assets/images/dropus.gif) no-repeat;
  width: 563px;
  height: 31px;
  text-indent: -9000px; }

h2.blogging {
  background: url(/assets/images/blogging.gif) no-repeat;
  width: 529px;
  height: 32px;
  text-indent: -9000px; }

h2.media {
  background: url(/assets/images/news.gif) no-repeat;
  width: 505px;
  height: 31px;
  text-indent: -9000px; }

h2.pics {
  background: url(/assets/images/pics.gif) no-repeat;
  width: 183px;
  height: 30px;
  text-indent: -9000px;
  margin-bottom: 10px; }

#gallery {
  height: 254px;
  overflow: auto; }

.thickbox img {
  padding: 1px;
  border: 1px solid #9ab2ff; }

.thickbox:hover img {
  border: 1px solid #fff; }

.person {
  width: 290px;
  float: left;
  margin: 0 12px; }

.person img {
  padding: 5px;
  background-color: #0b1228;
  margin-bottom: -2px; }

.person p.big {
  color: #afb6cc;
  font: normal 1.8em/1.5 Helvetica, Arial, Geneva, sans-serif;
  padding: 10px;
  background-color: #0b1228;
  width: 280px;
  margin: 0; }

.person p {
  margin: 10px 0;
  font: normal 1.2em/1.7 Helvetica, Arial, Geneva, sans-serif;
  color: #afb6cc; }

.entry-content a {
  color: #0068bf; }

.entry-content a:hover {
  color: #005095; }

.entry-title a {
  color: #fff;
  font: bold 2.7em/1.7 Helvetica, Arial, Geneva, sans-serif;
  text-decoration: none; }

.published {
  color: #fff;
  font: normal 1.4em/1.2 Helvetica, Arial, Geneva, sans-serif; }

.post_credits {
  color: #7296ff;
  font: normal 1.4em/1.2 Helvetica, Arial, Geneva, sans-serif; }

.post_credits a {
  color: #fff; }

#footer {
  width: 960px;
  margin: 0 auto 20px;
  background: url(/assets/images/hr.gif) no-repeat;
  padding: 2px 0 0 0; }

#footer p {
  color: #afb6cc;
  font: normal 1.4em/1.5 Helvetica, Arial, Geneva, sans-serif;
  padding: 5px 0; }

#footer p a {
  color: #9ab2ff;
  text-decoration: none; }

#footer p a:hover {
  color: #648ed7; }

.right {
  float: right; }

/* thickbox */
/* ----------------------------------------------------------------------------------------------------------------*/
/* ---------->>> global settings needed for thickbox <<<-----------------------------------------------------------*/
/* ----------------------------------------------------------------------------------------------------------------*/
* {
  padding: 0;
  margin: 0; }

/* ----------------------------------------------------------------------------------------------------------------*/
/* ---------->>> thickbox specific link and font settings <<<------------------------------------------------------*/
/* ----------------------------------------------------------------------------------------------------------------*/
#TB_window {
  font: 12px Arial, Helvetica, sans-serif;
  color: #333333;
  min-width: 140px; }

#TB_secondLine {
  font: 10px Arial, Helvetica, sans-serif;
  color: #666666; }

#TB_window a:link {
  color: #666666; }

#TB_window a:visited {
  color: #666666; }

#TB_window a:hover {
  color: #000; }

#TB_window a:active {
  color: #666666; }

#TB_window a:focus {
  color: #666666; }

/* ----------------------------------------------------------------------------------------------------------------*/
/* ---------->>> thickbox settings <<<-----------------------------------------------------------------------------*/
/* ----------------------------------------------------------------------------------------------------------------*/
#TB_overlay {
  position: fixed;
  z-index: 100;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%; }

.TB_overlayMacFFBGHack {
  background: url(/admin/admin/theme//assets/images/macFFBgHack.png) repeat; }

.TB_overlayBG {
  background-color: #000;
  filter: alpha(opacity=75);
  -moz-opacity: 0.75;
  opacity: 0.75; }

* html #TB_overlay {
  /* ie6 hack */
  position: absolute;
  height: expression(document.body.scrollHeight > document.body.offsetHeight ? document.body.scrollHeight : document.body.offsetHeight + 'px'); }

#TB_window {
  position: fixed;
  background: #ffffff;
  z-index: 102;
  color: #000000;
  display: none;
  border: 4px solid #525252;
  text-align: left;
  top: 50%;
  left: 50%; }

* html #TB_window {
  /* ie6 hack */
  position: absolute;
  margin-top: expression(0 - parseInt(this.offsetHeight / 2) + (TBWindowMargin = document.documentElement && document.documentElement.scrollTop || document.body.scrollTop) + 'px'); }

#TB_window img#TB_Image {
  display: block;
  margin: 15px auto; }

#TB_caption {
  height: 25px;
  padding: 7px 30px 10px 25px;
  float: left; }

#TB_closeWindow {
  height: 25px;
  padding: 11px 25px 10px 0;
  float: right; }

#TB_closeAjaxWindow {
  padding: 7px 10px 5px 0;
  margin-bottom: 1px;
  text-align: right;
  float: right; }

#TB_ajaxWindowTitle {
  float: left;
  padding: 7px 0 5px 10px;
  margin-bottom: 1px; }

#TB_title {
  background-color: #e8e8e8;
  height: 27px; }

#TB_ajaxContent {
  clear: both;
  padding: 2px 15px 15px 15px;
  overflow: auto;
  text-align: left;
  line-height: 1.4em; }

#TB_ajaxContent.TB_modal {
  padding: 15px; }

#TB_ajaxContent p {
  padding: 5px 0px 5px 0px; }

#TB_load {
  position: fixed;
  display: none;
  height: 13px;
  width: 208px;
  z-index: 103;
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -104px;
  /* -height/2 0 0 -width/2 */ }

* html #TB_load {
  /* ie6 hack */
  position: absolute;
  margin-top: expression(0 - parseInt(this.offsetHeight / 2) + (TBWindowMargin = document.documentElement && document.documentElement.scrollTop || document.body.scrollTop) + 'px'); }

#TB_HideSelect {
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  border: none;
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  opacity: 0;
  height: 100%;
  width: 100%; }

* html #TB_HideSelect {
  /* ie6 hack */
  position: absolute;
  height: expression(document.body.scrollHeight > document.body.offsetHeight ? document.body.scrollHeight : document.body.offsetHeight + 'px'); }

#TB_iframeContent {
  clear: both;
  border: none;
  margin-bottom: -1px;
  margin-top: 1px;
  _margin-bottom: 1px; }
